
// Vue reactivity
import { computed, ref, watch } from "vue";

// icons
import { star, starHalfOutline, starOutline, pencil, heart, heartOutline,
        cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline } from "ionicons/icons";

// components
import { IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
        IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton,
        IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
        IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
        loadingController, modalController } from "@ionic/vue";
import ProductReviewModal from '@/components/ProductReviewModal.vue';
import ImageSlides from '@/components/ImageSlides.vue';

// API services
import ProductService from '@/services/ProductService';

import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "ProductDetailPage",
  components: {
    IonPage, IonHeader, IonToolbar, IonBackButton, IonTitle, IonContent,
    IonGrid, IonSpinner, IonRow, IonCol, IonButtons, IonButton,
    IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent,
    IonIcon, IonAvatar, IonLabel, IonItem, IonChip, IonNote, IonSelect, IonSelectOption,
    ImageSlides,
  },
  setup() {
    // methods or filters
    const store = useStore();
    const { t, locale } = useI18n();
    const { formatDate, presentToast } = utils();
    const route = useRoute();
    const parentPath = route.params.parentPath; // may be from liked items page
    const currProductId = route.params.id;

    // 1. declare state variables (ref to make them reactive) / methods
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const product = computed(() => store.getters.getProductById(currProductId));
    const productOverallRating = ref(0);
    const productReviews = ref<any>([]);
    const loadingProductReviews = ref(true);
    const productVariants = ref<any>([]);
    const loadingProductVariants = ref(false);

    const selectedProductVariant = ref<any>({});
    const cartItemQty = ref(1);

    const selectedProductInStock = computed(() => {
      return selectedProductVariant.value.id ? selectedProductVariant.value.inStock : product.value.inStock;
    });
      
    const decrementCartItemQty = () => {
      if (cartItemQty.value > 1) cartItemQty.value--;
    }
    const incrementCartItemQty = () => {
      cartItemQty.value++;
    }
    const onFinishInputCartItemQty = (e: any) => {
      const inputQty = e.target.value;
      cartItemQty.value = inputQty > 0 ? Math.ceil(+inputQty) : +cartItemQty.value + 1;
    }
    const addProductToCart = async (product: any, variant: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('addProductToCart', { product, addQty: cartItemQty.value, variant });
      loading.dismiss();
      presentToast( t('successAddedToCart'), 2000, 'top' );
    }
    const updateUserLikedItem = async (product: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') await ProductService.addUserLikedItem(product.id);
      else await ProductService.removeUserLikedItem(product.id);
      product.likedByUser = (action == 'add');
      loading.dismiss();
      presentToast( t('successUpdateLikedItems'), 2000, 'top' );
    }
    const fetchProductReviews = (productId: any) => {
      loadingProductReviews.value = true;
      ProductService.getProductReviews(productId).then(res => {
        loadingProductReviews.value = false;
        productReviews.value = res;
        if (res.length > 0) {
          productOverallRating.value = res.reduce((total: any, next: any) => total + Number(next.rating), 0) / res.length;
        }
      })
    }
    const openNewReviewModal = async () => {
      const modal = await modalController.create({
        component: ProductReviewModal,
        componentProps: { product: product.value },
      });
      modal.onDidDismiss().then(({ data }) => {
        if (data.newProductReviewCreated) {
          fetchProductReviews(currProductId);
        }
      })
      return modal.present();
    };
    const fetchProductVariants = (targetProduct: any) => {
      if (targetProduct.relatedProductVariants) { // exist product variants
        loadingProductVariants.value = true;
        ProductService.getProductVariants(targetProduct.id).then(res => {
          loadingProductVariants.value = false;
          productVariants.value = res;
          selectedProductVariant.value = res[0]; // default select the first variant
        })
      }
    }
    fetchProductReviews(currProductId);

    if (product.value.id) {
      fetchProductVariants(product.value);
    } else {
      watch(product, (currProduct) => { // triggered only when direct access to product detail page
        fetchProductVariants(currProduct);
      })
    }

    const getLocalizedStr = (dataObj: any, keyChi: any, keyEn: any) => {
      return locale.value == 'zh' ? dataObj[keyChi] : (dataObj[keyEn] || dataObj[keyChi]);
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // methods
      t, formatDate, addProductToCart, updateUserLikedItem, openNewReviewModal,
      incrementCartItemQty, decrementCartItemQty, onFinishInputCartItemQty,
      getLocalizedStr,

      // icons
      star, starHalfOutline, starOutline, pencil, heart, heartOutline,
      cartOutline, cardOutline, add, remove, chatbubbleEllipsesOutline,

      // variables
      userLoggedIn, parentPath, loading, product, cartItemQty,
      loadingProductVariants, productVariants, selectedProductVariant,
      loadingProductReviews, productReviews, productOverallRating,
      selectedProductInStock,
      locale,
    };
  },
};
