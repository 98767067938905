
// icons
import { heart, heartOutline, cartOutline } from "ionicons/icons";

// components
import { IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
          IonChip, IonLabel, IonIcon, IonButtons, IonButton,
          loadingController, } from "@ionic/vue";

// API services
import ProductService from '@/services/ProductService';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

export default {
  props: [
    "product",
    "hideDetails",
    "extraRouterParams",
    "userLoggedIn",
    "isFeatured",
  ],
  components: {
    IonBadge, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent,
    IonChip, IonLabel, IonIcon, IonButtons, IonButton,
  },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const { presentToast } = utils();

    const addProductToCart = async (product: any) => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('addProductToCart', { product });
      loading.dismiss();
      presentToast( t('successAddedToCart'), 2000, 'top' );
    }
    const updateUserLikedItem = async (product: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      if (action == 'add') await ProductService.addUserLikedItem(product.id);
      else await ProductService.removeUserLikedItem(product.id);
      product.likedByUser = (action == 'add');
      loading.dismiss();
      presentToast( t('successUpdateLikedItems'), 2000, 'top' );
    }

    return {
      // icons
      cartOutline, heart, heartOutline,

      // methods
      t, addProductToCart, updateUserLikedItem,

      // variables
      locale
    };
  },
};
